import { Col, Row } from "antd";
import Text from "../Ui/Typography/Text";
import React from 'react'
const BulletPoints = ({title, bullets}) => (
    <Col style={{marginTop: 40}}>
        <Text level='t4' upperSeperators colorType='orange'>
            {title}
        </Text>
        <Col style={{marginTop: 10}}>
        {bullets.map((item, index)=><Row style={{marginBottom: 5}} key={index}>
        <Text level='t4'>• {item}
        </Text></Row> )}
        </Col>
        </Col>
)

export default BulletPoints;