import React from 'react';
import Container from '../components/container';
import SEO from '../components/seo';
import { Col, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { graphql, useStaticQuery } from 'gatsby';
import Content from '../components/Layout/Content';
import Title from '../Ui/Typography/Title';
import Span from '../Ui/Typography/Span';
import Text from '../Ui/Typography/Text';
import styled from 'styled-components';
import Section from '../components/Layout/Section';
import { media } from '../Ui/text-sizes';
import Team from '../assets/tpl_team.jpg';
import linkedin from '../assets/linkedin.png';
import ShowMore from '../assets/show-more.png';
import ShowLess from '../assets/show-less.png';
import ShowMoreText from 'react-show-more-text';
import BulletPoints from '../components/SectionBullet';
import Button from '../Ui/Buttons';

const SecondPage = () => {
  return (
    <Container defKey='1' title='Careers / Software Team Lead'>
      <SEO title='Software Team Lead' />
      <Content>
        <Col xs={24} sm={12} lg={15}>
          <Title level={2} color='darkGray' topSpace={19} bottomSpace={34} mobileBottomSpace={20} letterMd>
            Software Team Lead
          </Title>
        </Col>
          <Text level='t4'>
           Lead a team of software engineers providing direction, training and guidance to implement software solutions. Work with a cross functional team, which includes developers, product/project managers, QA engineers, designers to implement solutions tightly aligned with business objectives. Partner with other engineering leads to ensure cloud infrastructure solutions are designed and built with a high level of quality, security and scalability. Utilize the latest full stack technologies and cloud services to produce exceptional software within a continuous integration/continuous delivery environment. Regularly communicate project updates, progress, risks, and issues to internal and external stakeholders.
        </Text>
        <Col style={{marginTop: 40}}>
        <Text level='t4' upperSeperators colorType='orange'>
            The team
        </Text>
        <Col style={{marginTop: 10}}>
        <Text level='t4'>
            We are a cross-functional team of product managers, designers, and engineers. Our team works with founders of cutting edge products and brings their ideas from prototype to live. 
        </Text>
        </Col>
        </Col>
        <BulletPoints bullets={[
            'Lead and guide a team of software engineers to build high quality software',
            'Collaborate with Product/Project Managers, designers & other engineers to translate customer business needs into workable, technical solutions in a timely manner.',
            'You will report directly to Chief Software Architect',
            'Partner with internal stakeholders to influence action on unique client requests.',
            'Regularly communicate project updates, progress, risks, and issues to internal and external stakeholders.',
            'Design and develop the frontend and backend architecture for web/mobile applications. ',
            'Maintain and advance existing web/mobile application',
            'Lead development projects from proof of concept to finished product',
            'Ensure and improve efficiency of applications',
            'Create unit tests for robustness, including edge cases, usability, and general reliability',
            'Set up servers and cloud based services to support CI/CD application development',
            'Work within an agile workflow environment managing and completing tasks',
            'Assist in task planning, estimation, ',
            'Mentor developers of all skill levels on software development best practices'
        ]} title={'The responsibilities'} />

        <BulletPoints title={'What We Are Looking For'} bullets={[
            'Experience with JavaScript, and HTML/CSS',
            'Experience developing, debugging, and testing web applications',
            'Experience with REST and/or GraphQL and web service design',
            'English language fluency, excellent communication skills',
            'Excellent team player'
]} />
 <BulletPoints title={'Bonus Points'} bullets={[
            'Experience with Node.js',
'Experience with React',
'Experience with React Native',
'Experience with SQL databases',
'Having experience with multi-tenant architectures and cloud-native infrastructure, like Kubernetes, is a big plus',

]} /> <BulletPoints title={'What You Can Expect From Us'} bullets={[
           'Accelerate your career growth by joining one of the most exciting opportunities to serve in a partner’s interim founding engineering team to take their idea from prototype to product',
            'We will help you grow your skills through mentorships and education support. Our environment is focused on collaboration and learning',
            'A high degree of collaboration and autonomy while working with a group of diverse peers, and access to cutting edge technologies',
            'You will have a direct impact on the success of our projects',
]} />
        <Row style={{marginTop: 40}} >
            <a href={'mailto:team@tidepoollabs.com?subject=Apply for team lead position'}>
                <Button  type='primary' shape='round'>
                Apply Now
                </Button>
            </a>
        </Row>

      </Content>
      <Section spaceTop={1} mobileTopSpace={0}>
        
      </Section>
      <Section secondary mobileBottomSpace={40}>
        <Text level='t4' upperSeperators colorType='orange'>
          Our team
        </Text>
        <Col xs={24} sm={13} lg={14}>
          <Title level={2} color='darkGray' topSpace={29} mobileBottomSpace={20} letterMd bottomSpace={34}>
            We are<Span type='gradient2'> dedicated</Span> problem solvers
             </Title>
        </Col>
        <Col xs={24} sm={20} lg={16}>
          <Text level='t4'>
            Tidepool Labs gives entrepreneurs what they need most - top tier engineers, experienced advisors, and funding.
          </Text>
        </Col>
        <Col xs={24}>
          <ImageTeam src={Team} width='100%' />
        </Col>
      </Section>
    </Container>
  )
};

const SocialIcon = styled.img`
  width: 25px;
  
  ${media.down('md')} {
    margin-bottom: 20px;
    margin-top: 17px;
  }
  
  ${media.down('md')} {
    width: 33px;
  }
`;

const ImageTeam = styled.img`
  width:100%;
  margin-top: 55px;
  
  ${media.down('md')} {
    margin-top: 40px;
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
  } 
`;

const I = styled.img`
  width:338px;
  
  ${media.down('md')} {
    width:100%;
    margin-bottom: 28px;
  }
`;

const R = styled(Row)`
  margin-top: 63px;
  
  ${media.down('md')} {
    margin-top: 42px;
  }
`;

export default SecondPage;

